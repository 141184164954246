import * as React from 'react'
import { useState } from 'react'
import FocusLock from 'react-focus-lock'
import { isAfter, isBefore } from 'date-fns'

import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import config from 'core/src/config'

import NewsScreen from 'news/screens/NewsScreen'
import EditoScreen from 'edito/screens/EditoScreen'

import useI18n from 'i18n/useI18n'

import { windowSizeBreakpoints } from 'utils/breakpoints'
import analytics, { analyticsKeys } from 'utils/analytics'
import { getScreenNameFromFeatureType, processFeatures } from 'home/utils'
import stringUtils from 'utils/strings'
import { isBookingServiceVisible } from 'restaurants/utils/utils'
import { checkOrigin } from 'core/src/layout/utils'
import useNavigation from 'utils/navigation/useNavigation'
import Logger from 'utils/Logger'

import barclapApi from 'planning/api'
import surveysApi from 'surveys/api'
import accountApi from 'account/accountApi'
import api from 'planning/api'

import Shortcut from 'components/button/Shortcut'
import InstallAppModal from 'components/modal/InstallAppModal'
import TitleHelmet from 'components/title/TitleHelmet'
import ServiceDetails from 'restaurants/screens/ServiceDetails'
import MyMRSReloadModal from 'restaurants/mrs/MyMRSReloadModal'
import Modal from 'components/modal/Modal'
import ModalContent from './ModalContent'
import Icons from 'components/icons/Icons'
import RestaurantHomeContent from 'restaurants/screens/RestaurantHomeContent'
import Banner from 'components/banner/Banner'

import useReducer from 'store/useReducer'
import * as userStore from 'store/user/user'
import * as shortcutStore from './store/shortcutStore'
import * as siteStore from 'sites/store/siteStore'
import * as featureStore from 'sites/store/featureStore'
import * as planningStore from 'planning/planningStore'
import * as bannersStore from 'banners/store'

import EngieMSAL from 'authent365/azure/MSALAuth'
import EquansMSAL from 'authent365/azure/MSALEquans'
import { refreshSSOToken } from 'planning/ssoToken'

type ServicesBarclap = 'commuTable' | 'tableService' | 'packedLunch'

const NOW = new Date()

const BORDER_RADIUS_MODAL = 16

const HomeScreen = () => {
  const [Theme] = useTheme()
  const i18n = useI18n()
  const navigation = useNavigation()

  const user = useReducer(userStore.store, (s) => s.user)
  const site = useReducer(siteStore.store, (s) => s.site)
  const allSiteStore = useReducer(siteStore.store, (s) => s)
  const mySites = allSiteStore.mySites
  const allSites = allSiteStore.allSites
  const shortcuts = site ? useReducer(shortcutStore.store, (s) => s.sitesShortcuts[site.id]) : undefined
  const features: FeatureV4[] = useReducer(featureStore.store, (f) => f.features)
  const userZapfloorId = useReducer(planningStore.store, (s) => s.zapfloorUserId)
  const badges = useReducer(userStore.store, (s) => s.badges)
  const parkingUrl = useReducer(siteStore.store, (s) => s.parkingUrl)
  const seeDownloadApp = useReducer(userStore.store, (s) => s.seeDownloadApp)
  const banners = useReducer(bannersStore.store, (s) => s.banners)

  const [modify, setModify] = useState(false)
  const [packedLunchBooking, setPackedLunchBooking] = React.useState<BarclapBooking>()
  const [tableCommuBooking, setTableCommuBooking] = React.useState<BarclapBooking>()
  const [tableServiceBooking, setTableServiceBooking] = React.useState<BarclapBooking>()
  const [tableCommu, setTableCommu] = React.useState<BarclapModuleDatas>()
  const [tableService, setTableService] = React.useState<BarclapModuleDatas>()
  const [packedLunch, setPackedLunch] = React.useState<BarclapModuleDatas>()
  const [surveysList, setSurveysList] = React.useState<Survey[]>([])

  const showedBanner = React.useMemo(() => banners.find((banner) => !!banner.feature && banner.feature === 'HOME'), [
    banners,
  ])

  const updateUserGraphInfos = () => {
    if (user) {
      if (user.type === 'ENGIE') {
        EngieMSAL.me()
          .then((res) => accountApi.getInfo('ENGIE').then((u) => userStore.actions.setUser({ ...res, officeId: u.id })))
          .catch(Logger.error)
      } else if (user.type === 'EQUANS') {
        EquansMSAL.me()
          .then((res) =>
            accountApi.getInfo('EQUANS').then((u) => userStore.actions.setUser({ ...res, officeId: u.id }))
          )
          .catch(Logger.error)
      }
    }
  }

  const servicesVisible = React.useMemo(() => {
    const servicesList: ServicesBarclap[] = []
    if (!!site && !!site.barclapId) {
      if (!!packedLunch && !!packedLunch.module_timeslots[0]) {
        const isOpen =
          isAfter(NOW, new Date(`${stringUtils.getYMDFromDate(NOW)}T${packedLunch.module_timeslots[0].start_time}Z`)) &&
          isBefore(NOW, new Date(`${stringUtils.getYMDFromDate(NOW)}T${packedLunch.module_timeslots[0].end_time}Z`))
        if ((isOpen && packedLunch.module_timeslots[0].remaining_booking > 0) || !!packedLunchBooking) {
          servicesList.push('packedLunch')
        }
      }
      if (!!tableCommu && !!tableCommuBooking && isBookingServiceVisible(tableCommuBooking)) {
        servicesList.push('commuTable')
      }
      if (!!tableService && !!tableServiceBooking && isBookingServiceVisible(tableServiceBooking)) {
        servicesList.push('tableService')
      }
    }
    return servicesList
  }, [site, packedLunchBooking, tableCommuBooking, tableServiceBooking, packedLunch, tableCommu, tableService])

  const currentBadge = React.useMemo(() => (!!site ? badges.find((b) => b.siteId === site.id) : undefined), [
    site,
    badges,
  ])
  const solde = React.useMemo(() => currentBadge?.solde, [currentBadge])

  const isSiteSelectedIsMySite = React.useMemo(() => !!mySites.find((mySite) => mySite.id === site?.id), [
    mySites,
    site,
  ])

  const hasSitesShortcut = React.useMemo(
    () => !!user && (user.type === 'EXTERNAL' ? mySites.length > 1 : allSites.length > 1),
    [user, allSites, mySites]
  )

  const hasAccessNews = !!features && features.map((f) => f.type).includes('NEWS')

  const hasAccessEdito = !!features && features.map((f) => f.type).includes('PRACTICAL_INFORMATION')

  React.useEffect(() => {
    updateUserGraphInfos()
    openInstallAppPopUp()
  }, [])

  React.useEffect(() => {
    if (!!user && !!site) {
      refreshSSOToken(user.type, site.id, 'ZAPFLOOR').catch((err) =>
        Logger.error('Error while refreshing SSO token : ', err)
      )

      if (!!userZapfloorId) {
        api.zapfloor
          .getAccessGroupsInfos(user.type, site.id, userZapfloorId, site.locationId!)
          .then((res) => {
            if (res.length > 0) {
              const allMaxDays = res.map((r) => r.attributes.max_days_in_future)

              const findMaxDaysInFuture = allMaxDays.reduce((acc, cur) => {
                if (!cur || acc > cur) {
                  return acc
                }
                return cur
              })

              planningStore.actions.setMaxDaysInFuture(findMaxDaysInFuture || undefined)
            } else {
              planningStore.actions.setMaxDaysInFuture(undefined)
            }
          })
          .catch((err) => {
            Logger.error('Error while getAccessGroupsInfos : ', err)
            planningStore.actions.setMaxDaysInFuture(undefined)
          })
      }
    }
  }, [user, userZapfloorId, site])

  React.useEffect(() => {
    fetchBarclapInfos()
    fetchSurveyInfos()

    if (user) {
      analytics.track({ screenName: analyticsKeys.home, userType: user.type, currentSite: site, mySites })
    }
  }, [user, site])

  const allShortcuts = [
    ...processFeatures(navigation, features, allSiteStore, i18n, user, parkingUrl, 'raccourcis'),
    {
      name: 'account',
      icon: 'user',
      position: 0,
      onClick: () => navigation.push('/account'),
      shortcut: false,
      path: '/account',
    } as ProcessedFeatures,
    {
      name: 'sites',
      icon: 'settings',
      position: 0,
      onClick: () => navigation.push('/sites', { origin: 'raccourcis' }),
      shortcut: false,
      path: '/sites',
    } as ProcessedFeatures,
  ]

  const openInstallAppPopUp = () => {
    if (!seeDownloadApp) {
      const origin = checkOrigin()
      if (origin === 'iPhone' || origin === 'android') {
        userStore.actions.setSeeDownloadApp(true)
        Modal.open(() => <InstallAppModal origin={origin} />, undefined, undefined, BORDER_RADIUS_MODAL)
      }
    }
  }

  const shortcutScreens = allShortcuts
    .filter(
      (screen) =>
        shortcuts &&
        (shortcuts.list.map((s) => getScreenNameFromFeatureType(s.name)).indexOf(screen.name) >= 0 ||
          (!!screen.id && shortcuts.list.map((s) => s.id).indexOf(screen.id) >= 0))
    )
    .sort((a, b) =>
      shortcuts
        ? shortcuts.list.map((s) => s.name).indexOf(a.name) - shortcuts.list.map((s) => s.name).indexOf(b.name)
        : 0
    )

  const deleteShortcut = (label: string) => {
    if (shortcuts && site) {
      const updatedShortcut = {
        siteId: site.id,
        list: shortcuts.list.filter((s) => s.name !== label),
        isDefault: false,
      }
      shortcutStore.actions.setSiteShortcuts(updatedShortcut)
    }
  }

  const toggleModify = () => setModify((m) => !m)

  const formatBarclapDate = (date: Date) => i18n.t('formats.utcDate', { date: date })

  const fetchSurveyInfos = () => {
    if (!!user && !!site) {
      surveysApi
        .getSurveysList(user.type, site.id)
        .then((res) => setSurveysList(res.surveys))
        .catch(Logger.error)
    }
  }

  const fetchBarclapInfos = () => {
    if (user && site && site.barclapId) {
      Promise.all([
        barclapApi.barclap.getSiteModules(user.type, site.id),
        barclapApi.barclap.getBookings(
          user.type,
          site.id,
          site.barclapId!,
          formatBarclapDate(NOW),
          formatBarclapDate(NOW)
        ),
      ])
        .then((res) => {
          const findPackedLunchBooking = res[1].find(
            (booking) => booking.module_uuid === config.barclap.packedLunchUUID
          )
          setPackedLunchBooking(findPackedLunchBooking)
          const findCommuTableBooking = res[1].find((booking) => booking.module_uuid === config.barclap.tableCommuUUID)
          setTableCommuBooking(findCommuTableBooking)
          const findTableServiceBooking = res[1].find(
            (booking) => booking.module_uuid === config.barclap.tableServiceUUID
          )
          setTableServiceBooking(findTableServiceBooking)
          const findSiteModules = res[0].find((s) => s.site_uuid === site.barclapId)
          if (!!findSiteModules) {
            const filterModules = findSiteModules.teams[0].modules.filter(
              (m) =>
                m.module_uuid === config.barclap.packedLunchUUID ||
                m.module_uuid === config.barclap.tableCommuUUID ||
                m.module_uuid === config.barclap.tableServiceUUID
            )
            const promises = filterModules.map((moduleInfo) =>
              barclapApi.barclap.getModuleInfos(
                moduleInfo.module_uuid === config.barclap.packedLunchUUID ? 's4d9' : 's4d1',
                user.type,
                site.id,
                moduleInfo.module_uuid,
                site.barclapId!,
                formatBarclapDate(NOW)
              )
            )
            Promise.all(promises)
              .then((res) => {
                res.map((data) => {
                  if (data.module.module_uuid === config.barclap.tableServiceUUID) {
                    setTableService(data)
                  } else if (data.module.module_uuid === config.barclap.tableCommuUUID) {
                    setTableCommu(data)
                  } else if (data.module.module_uuid === config.barclap.packedLunchUUID) {
                    setPackedLunch(data)
                  }
                })
              })
              .catch(Logger.error)
          }
        })
        .catch(Logger.error)
    }
  }

  const renderServices = () => {
    if (servicesVisible.length === 0 && surveysList.length === 0 && !(!!solde || solde === 0)) {
      return null
    }
    return (
      <ServicesContainer>
        <ServicesListContainer>
          {solde !== undefined && solde !== null && (
            <ServiceItemContainer>
              <TitleText>{i18n.t('screens.catering.services.myBalance')}</TitleText>
              <ServiceDetails
                title={i18n.t(`screens.catering.services.myBalance`)}
                iconName="contactless-card"
                serviceName="solde"
                onClick={() => navigation.push('/mymrs')}
                onSecondaryClick={() => Modal.open(() => <MyMRSReloadModal closeModal={Modal.close} />)}
              />
            </ServiceItemContainer>
          )}
          {surveysList.length > 0 && (
            <ServiceItemContainer>
              <TitleText>{i18n.t('screens.surveys.title')}</TitleText>
              <ServiceDetails
                title={i18n.t('screens.surveys.title')}
                iconName="pencil"
                serviceName="surveys"
                onClick={() => navigation.push('/surveys')}
                surveysList={surveysList}
              />
            </ServiceItemContainer>
          )}
          {!!tableService && servicesVisible.includes('tableService') && (
            <ServiceItemContainer>
              <TitleText>{i18n.t('screens.catering.services.tableService')}</TitleText>
              <ServiceDetails
                title={i18n.t(`screens.catering.services.tableService`)}
                barclapModuleData={tableService}
                iconName="packed-lunch"
                serviceName="tableService"
                onClick={() =>
                  navigation.push('/catering', {
                    initialService: 'tableService',
                  })
                }
                packedLunchBooking={packedLunchBooking}
                tableCommuBooking={tableCommuBooking}
                tableServiceBooking={tableServiceBooking}
              />
            </ServiceItemContainer>
          )}
          {!!tableCommu && servicesVisible.includes('commuTable') && (
            <ServiceItemContainer>
              <TitleText>{i18n.t('screens.catering.services.commuTable')}</TitleText>
              <ServiceDetails
                title={i18n.t(`screens.catering.services.commuTable`)}
                barclapModuleData={tableCommu}
                iconName="users-wm"
                serviceName="commuTable"
                onClick={() =>
                  navigation.push('/catering', {
                    initialService: 'commuTable',
                  })
                }
                packedLunchBooking={packedLunchBooking}
                tableCommuBooking={tableCommuBooking}
                tableServiceBooking={tableServiceBooking}
              />
            </ServiceItemContainer>
          )}
          {!!packedLunch && servicesVisible.includes('packedLunch') && (
            <ServiceItemContainer>
              <TitleText>{i18n.t('screens.catering.services.packedLunch')}</TitleText>
              <ServiceDetails
                title={i18n.t(`screens.catering.services.packedLunch`)}
                barclapModuleData={packedLunch}
                iconName="bag-delivery"
                serviceName="packedLunch"
                onClick={() =>
                  navigation.push('/catering', {
                    initialService: 'packedLunch',
                  })
                }
                packedLunchBooking={packedLunchBooking}
                tableCommuBooking={tableCommuBooking}
                tableServiceBooking={tableServiceBooking}
              />
            </ServiceItemContainer>
          )}
        </ServicesListContainer>
      </ServicesContainer>
    )
  }

  return (
    <>
      <TitleHelmet title={i18n.t('screens.home.title')} />
      <MainContainer>
        {!isSiteSelectedIsMySite && (
          <HeadbandContainer>
            <IconContainer>
              <Icons name="warning-circle" color={Theme.colors.blue} size={26} />
            </IconContainer>
            <div>
              <HeadbandTitle>{i18n.t('screens.home.visitorTitle')}</HeadbandTitle>
              <HeadbandSubtitle>{i18n.t('screens.home.visitorSubtitle')}</HeadbandSubtitle>
            </div>
          </HeadbandContainer>
        )}

        {site && (
          <ImgContainer>
            <WelcomeImage
              src={site.image || require('components/image/assets/site_placeholder.jpg').default}
              alt=""
              aria-hidden
            />
            <SiteContainer
              onClick={() => navigation.push('/sites', { origin: 'home' })}
              aria-label={i18n.t('label.ariaLabel.header.toSites')}>
              <SiteLabel>{site.name}</SiteLabel>
            </SiteContainer>
          </ImgContainer>
        )}
        <TextContainer>
          <TopBarContainer>
            {!!showedBanner && <Banner banner={showedBanner} marginTop={20} />}
            <Title>
              <TitleHello>{i18n.t('screens.home.hello')}</TitleHello>
              {user && (
                <UserNameContainer>
                  <UserName onClick={() => navigation.push('/account')}>{user.firstName}</UserName>
                </UserNameContainer>
              )}
            </Title>
            <ShortcutBar disabled={!modify} autoFocus={false}>
              <ModifyContainer>
                <Modify onClick={toggleModify} tabIndex={0} aria-label={i18n.t('label.ariaLabel.home.modify')}>
                  {i18n.t(`common.${modify ? 'finish' : shortcutScreens.length > 0 ? 'modify' : 'addShortcut'}`)}
                </Modify>
              </ModifyContainer>
              <nav>
                <Shortcuts>
                  {shortcutScreens.map((screen) => (
                    <li key={screen.name}>
                      <Shortcut
                        iconSize={42}
                        circleSize={92}
                        onClick={() => {
                          if (!modify) {
                            screen.onClick()
                          }
                        }}
                        iconName={screen.icon as IconName}
                        modifying={modify}
                        label={screen.name}
                        deleteFunction={deleteShortcut}
                        thirdParty={!screen.path}
                        ariaLabel={i18n.t('label.ariaLabel.home.shortcutTitle', { label: screen.name })}
                        disabledTime={screen.disabledTime}
                      />
                    </li>
                  ))}
                  {shortcutScreens.length < 4 && modify && (
                    <div>
                      <Shortcut
                        key="add"
                        onClick={() => Modal.open(() => ModalContent(hasSitesShortcut, allShortcuts))}
                        iconName="plus"
                        color={Theme.colors.middleGrey}
                        ariaLabel={i18n.t('label.ariaLabel.home.addAShortcut')}
                      />
                    </div>
                  )}
                </Shortcuts>
              </nav>
            </ShortcutBar>
          </TopBarContainer>
          {renderServices()}
          <RestaurantsContainer>
            <RestaurantHomeContent navigation={navigation} />
          </RestaurantsContainer>
          {hasAccessNews ? (
            <NewsScreen limit={3} isHomePage navigate={() => navigation.push('/news')} />
          ) : hasAccessEdito ? (
            <EditoScreen limit={3} displayedHome navigate={() => navigation.push('/information')} />
          ) : null}
        </TextContainer>
      </MainContainer>
    </>
  )
}

export default HomeScreen

const MainContainer = styled('div')`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 116px);
`
const Title = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 80px;
  margin-left: 10px;
  margin-top: 54px;
`
const UserNameContainer = styled('div')`
  display: flex;
`
const UserName = styled('p')`
  cursor: pointer;
  ${(props) => props.theme.fonts.h1};
  color: ${(props) => props.theme.colors.blue};
  font-size: 30px;
  margin: 0px;
`
const WelcomeImage = styled.img`
  width: 100%;
  height: 300px;
  object-fit: cover;
`
const ImgContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 300px;
  overflow: hidden;
  position: absolute;
  right: 0;
  background-color: ${(props) => props.theme.colors.middleGrey};
`

const TextContainer = styled.div`
  margin-top: 300px;
`
const Shortcuts = styled('ul')`
  display: flex;
  flex-direction: row;
  margin: 0px;
  padding: 0px;
  list-style-type: none;
  @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
`
const ModifyContainer = styled.div`
  display: flex;
  margin-right: 15px;
  @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
    align-self: flex-end;
  }
`
const Modify = styled('button')`
  ${(props) => props.theme.fonts.body};
  font-size: 12px;
  text-decoration: underline;
  &:hover {
    color: ${(props) => props.theme.colors.blue};
  }
  :focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.darkBlue};
  }
`
const ShortcutBar = styled(FocusLock)`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 18px;
  @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
    align-items: stretch;
  }
`
const TopBarContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin: 0px 100px 0px 5%;
  @media only screen and (max-width: ${windowSizeBreakpoints.big}px) {
    flex-direction: column;
    align-items: normal;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.medium}px) {
    margin-right: 50px;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
    margin-left: 35px;
  }
`
const TitleHello = styled('p')`
  ${(props) => props.theme.fonts.thinTitle};
  color: ${(props) => props.theme.colors.darkGrey};
  margin: 0px;
`

const ServicesContainer = styled('div')``
const ServicesListContainer = styled('div')`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 10px 5%;
  gap: 0px 20px;
  @media only screen and (max-width: ${windowSizeBreakpoints.medium}px) {
    margin-right: 50px;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
    margin-left: 35px;
  }
`

const TitleText = styled('h1')`
  ${(props) => props.theme.fonts.h3Bold};
  margin: 10px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    margin: 0px;
  }
`

const ServiceItemContainer = styled('div')`
  flex-direction: column;
  display: flex;
  flex: 1;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    margin-top: 20px;
  }
`

const RestaurantsContainer = styled('div')`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 30px 5%;
  padding: 0px 10px;
  gap: 0px 20px;
  @media only screen and (max-width: ${windowSizeBreakpoints.medium}px) {
    margin-right: 50px;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
    margin-left: 35px;
  }
`

const SiteContainer = styled('button')`
  border-radius: 12px 12px 0px 0px;
  background-color: ${(props) => props.theme.colors.blue};
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 8px 32px;
  text-align: center;
`

const SiteLabel = styled('label')`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.white};
  cursor: pointer;
`

const HeadbandContainer = styled('div')`
  height: 66px;
  width: calc(100% - 150px);
  background-color: ${(props) => props.theme.colors.whiteTransparent90};
  border-bottom: 1px solid ${(props) => props.theme.colors.blue};
  z-index: 10;
  position: fixed;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 12px 24px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    width: 100%;
  }
`

const IconContainer = styled('div')`
  padding-right: 24px;
`

const HeadbandTitle = styled('p')`
  padding-bottom: 8px;
  margin: 0px;
  ${(props) => props.theme.fonts.bodyBold};
`

const HeadbandSubtitle = styled('p')`
  margin: 0px;
  ${(props) => props.theme.fonts.menuSubtitle};
  @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
    margin-right: 58px;
  }
`
